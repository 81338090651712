import {
  getImageUrl,
  getItemUrl,
  getModuleName,
  getServiceType,
  getServiceTypeId,
  getString,
  isServiceOpenNowServe,
  isSpaceOrderBookingUserData,
  openExternalURL,
  serviceTypes,
  showRequestFunnel,
  translate
} from "@/services/utils";
import itemTypes from "@/utils/enums/itemTypes";
import { contentTypes } from "@/utils/enums/widgetsFrontpage.js";
import cmsModules from "@/utils/enums/cmsModules";
import moment from "moment-timezone";
import { EventBus } from "@/services/eventBus";
import cloneDeep from "lodash-es/cloneDeep";

const BOOKING_STATUS_CANCELED = 2;

const Mixin = {
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    },
    dateNow() {
      return this.$store.state.dateNow;
    },
    establishment() {
      return this.$store.state.establishment;
    },
    momentServer() {
      return moment(this.dateNow?.time);
    },
    services() {
      return this.$store.state.services;
    }
  },
  methods: {
    async getItemsForWidget(widget) {
      let response = null;
      switch (widget.contentType) {
        case contentTypes.SERVICES:
          response = await this.getServicesFromModule(widget.cmsModule, widget.itemType, widget.itemId);
          break;
        case contentTypes.PRODUCTS:
          {
            if (widget.itemType === itemTypes.ITEM_TYPE_CATALOGUE_V2) {
              response = await this.getProductsFromCatalogue(widget.itemId, widget.cmsModule, widget.servicePublicId);
            } else {
              response = await this.getProductsFromService(widget.itemId, widget.cmsModule);
            }
          }
          break;
        case contentTypes.UPCOMING_ACTIVITY:
          response = await this.getNextBookings();
          break;
        case contentTypes.NEXT_ACTIVITIES:
          response = await this.getNextActivities(widget);
          break;
        case contentTypes.OPEN_SERVICES:
          response = await this.getOpenServices();
          break;
        case contentTypes.AVAILABLE_SERVICES:
          response = await this.getServicesWithBookingAvailability();
          break;
        case contentTypes.PROMOTED_PRODUCTS:
          response = await this.getProductsWithPromotion();
          break;
        case contentTypes.MANUAL_CONTENT:
          response = widget.children;
          break;
        default:
          break;
      }
      return response;
    },
    getStoredItemsForWidget(widget) {
      let response = null;
      switch (widget.contentType) {
        case contentTypes.SERVICES:
          {
            const serviceType = serviceTypes[widget.cmsModule];
            response = this.$store.state.services[serviceType];
            if (!response) {
              return null;
            }
            const categories = this.$store.state.categories[serviceType];
            response = this.formatServicesResponse(
              response,
              widget.itemType === itemTypes.ITEM_TYPE_CATEGORY ? widget.itemId : null,
              categories
            );
          }
          break;
        case contentTypes.PRODUCTS:
          {
            const serviceType = serviceTypes[widget.cmsModule];
            const serviceTypeId = getServiceTypeId(serviceType);
            response = this.$store.state.products[widget.itemId];
            if (!response) {
              return null;
            }
            const categories = this.$store.state.productCategories[widget.itemId];
            response = this.formatProductsResponse(response, widget.cmsModule, serviceTypeId, categories);
          }
          break;
        case contentTypes.NEXT_ACTIVITIES:
          {
            if (widget?.grouping) {
              response = this.$store.state.services["grouping"]?.find(grouping => grouping.id === widget.grouping);
              if (!response) {
                return null;
              }
              response = this.formatGroupingCalendarResponse(response?.grouping);
            }
          }
          break;
      }
      return response;
    },
    async getServicesFromModule(module, itemType, itemId) {
      const serviceType = serviceTypes[module];
      let response = await this.$store.dispatch("getServices", serviceType);
      if (response) {
        const anyServiceHasCategory = response.find(service => service.category > 0);
        let responseCategories = null;
        if (anyServiceHasCategory) {
          responseCategories = await this.$store.dispatch("getCategories", serviceType);
        }
        const filterCategory = itemType === itemTypes.ITEM_TYPE_CATEGORY ? itemId : null;
        if (this.user?.userKey) {
          await this.addServicesBookingsProConfiguration(response);
          response = this.formatServicesResponse(response, filterCategory, responseCategories);
        } else {
          response = this.formatServicesResponse(response, filterCategory, responseCategories);
          setTimeout(async () => {
            await this.addServicesBookingsProConfiguration(response);
            response = this.formatServicesResponse(response, filterCategory, responseCategories);
          }, 500);
        }
      }
      return response;
    },
    async addServicesBookingsProConfiguration(services) {
      const spacesServices = [];
      if (services.length > 0) {
        services.forEach(service => {
          if (service.bookingSystem == 11) {
            spacesServices.push(service.id);
          }
        });
      }

      if (spacesServices.length) {
        const response = await this.$store.dispatch("getSpaceDayScheduleServiceList", {
          serviceList: spacesServices,
          date: moment().format("YYYY-MM-DD")
        });
        if (response?.schedules && Object.keys(response.schedules).length) {
          const dayLowerCase = moment().locale("en").format("dddd").toLowerCase();
          Object.keys(response.schedules).forEach(key => {
            if (response.schedules[key].intervals) {
              services.forEach(service => {
                if (service.id == key) {
                  const intervals = [];
                  response.schedules[key].intervals.forEach(interval => {
                    intervals.push({ starts: interval.starts, ends: interval.ends });
                  });
                  service.serviceTimetable = {
                    [dayLowerCase]: intervals
                  };
                }
              });
            }
          });
        }
      }
      return services;
    },
    async getProductsFromService(serviceId, module) {
      const serviceType = serviceTypes[module];
      const serviceTypeId = getServiceTypeId(serviceType);
      let response = await this.$store.dispatch("getProducts", { serviceType: serviceType, serviceId: serviceId });
      if (response) {
        const anyProductHasCategory = response.find(service => service.category > 0);
        let responseCategories = null;
        if (anyProductHasCategory) {
          responseCategories = await this.$store.dispatch("getProductCategories", {
            serviceType: serviceType,
            serviceId: serviceId
          });
        }
        response = this.formatProductsResponse(response, module, serviceTypeId, responseCategories);
      }
      return response;
    },
    async getProductsFromCatalogue(catalogueId, module, servicePublicId) {
      const serviceType = serviceTypes[module];
      const responseService = await this.$store.dispatch("getServicePublicId", {
        servicePublicId: servicePublicId,
        serviceType: serviceType
      });
      let response = await this.$store.dispatch("catalogues/getServicesCatalogueProducts", {
        catalogueId: catalogueId
      });
      if (response.results) {
        const serviceTypeId = getServiceTypeId(serviceType);
        response.results.forEach(product => {
          product.id = product.publicId;
          product.service = servicePublicId;
          const priceAddOn = product.addOns?.[0];
          if (priceAddOn?.options?.length > 0 && priceAddOn.options?.[0]?.price?.[0]?.value) {
            product.price = priceAddOn.options[0].price[0].value;
            product.currencyData = responseService?.currencyData;
          }
        });
        response = this.formatProductsResponse(response.results, module, serviceTypeId, null);
      }
      return response;
    },
    async getNextBookings() {
      let response = [];
      if (this.user.cliId) {
        const responseActivity = await this.$store.dispatch("getActivity");
        if (responseActivity) {
          response = this.formatBookingsResponse(responseActivity);
        }
      }
      const myScheduleObjects = this.addMyScheduleObjects();
      if (myScheduleObjects.length > 0) {
        response = response.concat(myScheduleObjects);
      }
      const now = this.getNowMoment();
      const halfAnHourMargin = new moment().subtract(30, "minutes");
      return response
        .filter(item => {
          if (item.itemType === itemTypes.ITEM_TYPE_BOOKING && item.status === BOOKING_STATUS_CANCELED) {
            return false;
          }
          if (item?.endHour) {
            const activityMoment = moment(item.startDate + " " + item.endHour);
            return now.isBefore(activityMoment);
          } else {
            const activityMoment = moment(item.startDate + " " + item.hour);
            return halfAnHourMargin.isBefore(activityMoment);
          }
        })
        .slice(0, 1);
    },
    async getNextActivities(widget) {
      const promises = [];
      const today = this.getNowMoment();
      const now = this.getNowMoment();
      const startDate = today.format().split("T")[0];
      const halfAnHourMargin = new moment().subtract(30, "minutes");
      const timezone = this.getTimezone();
      const endDate = today.add(7, "days").format().split("T")[0];
      let response = null;
      if (widget?.grouping) {
        const responseGetGrouping = await this.$store.dispatch("getService", {
          serviceId: widget.grouping,
          serviceType: "grouping"
        });
        response = this.formatGroupingCalendarResponse(responseGetGrouping?.grouping);
      } else if (widget?.calendars?.length > 0) {
        widget.calendars.forEach(calendar => {
          promises.push(
            this.$store.dispatch("getEventsCalendar", {
              query: "startDate=" + startDate + "&endDate=" + endDate,
              serviceType: "event_calendar",
              category: calendar.id
            })
          );
        });
        response = await Promise.all(promises);
        if (response?.length > 0) {
          response = this.formatActivitiesResponse(response, widget.calendars);
        }
      }
      response = response?.filter(item => {
        if (item?.endHour) {
          const activityMoment = moment.tz(item.eventDay + " " + item.endHour, timezone);
          return now.isBefore(activityMoment);
        } else {
          const activityMoment = moment.tz(item.eventDay + " " + item.hour, timezone);
          return halfAnHourMargin.isBefore(activityMoment);
        }
      });
      return response;
    },
    async getOpenServices() {
      const response = await this.$store.dispatch("frontpage/getFrontpageOpenServices");
      return response;
    },
    async getServicesWithBookingAvailability() {
      const response = await this.$store.dispatch("frontpage/getFrontpageServicesWithBookingAvailability");
      return response;
    },
    async getProductsWithPromotion() {
      const response = await this.$store.dispatch("frontpage/getFrontpageProductsWithPromotion");
      return response;
    },
    getTimezone() {
      let timezone = this.establishment.timezone;
      if (!timezone) {
        timezone = "Europe/Madrid";
      }
      return timezone;
    },
    getNowMoment() {
      const timezone = this.getTimezone();

      return moment.tz(timezone); //.startOf("day").add("22", "hours").add("05", "minutes");
    },
    formatServicesResponse(response, filterCategory, responseCategories) {
      const data = [];
      response.forEach(item => {
        if (!filterCategory || item.category === filterCategory) {
          const category = responseCategories?.find(cat => cat.id === item.category);
          data.push({
            itemType: itemTypes.ITEM_TYPE_SERVICE,
            itemId: item.id,
            serviceType: item.type,
            cmsModule: item.cmsModule,
            serviceId: item.id,
            photographs: item.photographs,
            translatableName: item.translatableName,
            translatableDescription: item.translatableDescription,
            locationDescription: item.locationDescription,
            openingHours: item.openingHours,
            priceFrom: item.priceFrom,
            price: item.priceFrom,
            currencyData: item.currencyData,
            serviceTimetable: item.serviceTimetable,
            temporaryClosed: item.temporaryClosed,
            highlightedText: isServiceOpenNowServe(item, this.momentServer) ? { en: "Open", es: "Abierto" } : undefined,
            categoryName: category ? category.categoryName : null,
            serviceOrder: item.serviceOrder
          });
        }
      });
      data.sort((a, b) => {
        return a.serviceOrder - b.serviceOrder;
      });
      return data;
    },
    formatProductsResponse(response, module, serviceType, responseCategories) {
      const data = [];
      response.forEach(item => {
        const category = responseCategories?.find(cat => cat.id === item.category);
        data.push({
          itemType: itemTypes.ITEM_TYPE_PRODUCT,
          itemId: item.id,
          serviceType: serviceType,
          cmsModule: module,
          serviceId: item.service,
          photographs: item.photographs,
          translatableName: item.translatableName,
          translatableDescription: item.translatableDescription,
          locationDescription: item.locationDescription,
          priceFrom: item.price,
          price: item.price,
          oldPrice: item.priceOld,
          currencyData: item.currencyData,
          appIcon: item.appIcon || undefined,
          categoryName: category ? category.categoryName : null,
          productOrder: item.productOrder,
          categoryOrder: category ? category.categoryOrder : 0,
          catalogue: item.catalogue || undefined
        });
      });
      data.sort((a, b) => {
        return a.categoryOrder - b.categoryOrder || a.productOrder - b.productOrder;
      });
      return data;
    },
    formatBookingsResponse(response) {
      const data = [];
      response.forEach(item => {
        let photographs = [];
        if (item.itemType === itemTypes.ITEM_TYPE_LEAD && item.product) {
          photographs = item.product.photographs;
        } else if (item.itemType === itemTypes.ITEM_TYPE_BOOKING && item?.photographs) {
          photographs = item.photographs;
        }
        data.push({
          itemType: item.itemType,
          itemId: item.itemId,
          serviceId: item.serviceId,
          translatableName: { en: item.description },
          productName: item.product?.translatableName || null,
          photographs: photographs,
          hour: item.hour,
          status: item.status,
          startDate: item.startDate,
          type: item.type,
          serviceType: item.serviceType,
          pax: item.pax,
          bookingSpace: item.bookingSpace || null
        });
      });
      return data;
    },
    addMyScheduleObjects() {
      const objects = [];
      const now = new moment();
      const mySchedule = this.$store.state.mySchedule;
      if (mySchedule && mySchedule[this.establishment.id]) {
        Object.keys(mySchedule[this.establishment.id]).forEach(key => {
          const item = mySchedule[this.establishment.id][key];
          item.startDate = item.eventDay;
          item.description = translate(item.translatableName);
          item.type = getString(this.strings, getModuleName(item.cmsModule), this.user);
          item.serviceType = serviceTypes[item.cmsModule];
          item.serviceId = item.service;
          item.id = ("" + item.id).replace(/:event/g, "") + ":event";
          item.pax = 1;
          objects.push(item);
        });
      }
      return objects;
    },
    formatActivitiesResponse(response, calendars) {
      let nextActivities = [];
      response.forEach((calendarResponse, index) => {
        calendarResponse.forEach(item => {
          nextActivities.push({ ...item, ...{ calendar: calendars[index] } });
        });
      });

      nextActivities = this.sortActivities(nextActivities);
      return nextActivities;
    },
    formatGroupingCalendarResponse(grouping) {
      let nextActivities = [];
      const now = new moment(this.momentServer);
      grouping.forEach(groupingCalendar => {
        groupingCalendar.calendar.forEach(entry => {
          const foundService = groupingCalendar.list.find(service => {
            return service.id === entry?.service;
          });
          var entryDate = entry.eventDay + " " + entry.endHour;
          var then = moment(entryDate);
          if (!now.isAfter(then)) {
            nextActivities.push({
              ...entry,
              translatableName: foundService?.translatableName,
              place: {
                serviceName: foundService?.place?.name
              },
              calendar: {
                id: groupingCalendar.id,
                categoryName: groupingCalendar.translatableName,
                icon: groupingCalendar.icon
              }
            });
          }
        });
      });
      nextActivities = this.sortActivities(nextActivities);
      return nextActivities;
    },
    sortActivities(activities) {
      activities = activities.sort((a, b) => {
        return (
          parseInt(a?.eventDay?.replace(/-/g, "") + a?.hour?.replace(":", "")) -
          parseInt(b?.eventDay?.replace(/-/g, "") + b?.hour?.replace(":", ""))
        );
      });
      return activities;
    },
    async getServicesExtraInfo(services, subtitleType) {},
    itemClicked(item) {
      switch (item.itemType) {
        case itemTypes.ITEM_TYPE_BOOKING:
        case itemTypes.ITEM_TYPE_LEAD:
          this.showTransaction(item);
          break;
        default:
          this.showItem(item);
          break;
      }
    },
    showItem(item) {
      if (item.website && typeof item.website === "object") {
        this.showWebsite(item);
        return;
      }
      if (item.cmsModule === cmsModules.MODULE_REQUEST_CHAT) {
        EventBus.$emit("enquiriesButtonAction", {
          itemType: itemTypes.ITEM_TYPE_ESTABLISHMENT,
          itemId: this.establishment.id,
          title: translate(item.translatableName)
        });
        return;
      }
      const ihaModules = [cmsModules.MODULE_GUEST_SERVICE, cmsModules.MODULE_AMENITIES, cmsModules.MODULE_HOUSEKEEPING];
      if (ihaModules.indexOf(item.cmsModule) !== -1 && item.itemType === itemTypes.ITEM_TYPE_PRODUCT) {
        showRequestFunnel(
          item.serviceId,
          item.itemId,
          undefined,
          item.cmsModule,
          getImageUrl(item.photographs[0], "original"),
          translate(item.translatableName),
          this.getString(item.serviceType),
          this.establishment.id,
          this.user
        );
        return;
      }
      const serviceType = getServiceTypeId(serviceTypes[item.cmsModule]);
      if (item.servicePublicId && item.itemType === itemTypes.ITEM_TYPE_CATALOGUE_V2) {
        item.serviceId = item.servicePublicId;
      }
      const path = getItemUrl(
        item.itemType,
        item.itemId,
        serviceType,
        null,
        item.cmsModule,
        item.serviceId,
        false,
        false,
        false,
        item.calendarType,
        item.catalogue
      );
      let query = {
        id: this.establishmentHash
      };
      if (item.translatableName && translate(item.translatableName).length > 0) {
        query.bubbleName = translate(item.translatableName);
      }
      if (path.indexOf("?") !== -1) {
        query = { ...query, ...Object.fromEntries(new URL(window.location.origin + path).searchParams) };
      }

      this.$router.push({
        path: path,
        query: query
      });
    },
    checkEnableEnquiries() {
      if (this.establishment?.requestChat?.allowed === true) {
        const event = new Event("enquiriesEnabled");
        dispatchEvent(event);
      }
    },
    showWebsite(item) {
      const url = translate(item.website);
      if (item.openWebsiteInBrowser) {
        openExternalURL(url, "_blank");
      } else {
        const title = item.widgetTitleOptions?.title
          ? translate(item.widgetTitleOptions.title)
          : translate(item.translatableName);
        this.$router.push({
          path: "/external-webview",
          state: {
            params: {
              url: url,
              title: title,
              fullscreen: true
            }
          },
          name: "ExternalWebview"
        });
      }
    },

    goToSurveyChat(survey) {
      this.$router.push({
        name: "SurveyChat",
        state: {
          params: cloneDeep({
            surveyData: survey
          })
        },
        query: { id: this.$store.state.establishmentHash }
      });
    },
    showTransaction(item) {
      if (item?.type === "surveys") {
        this.goToSurveyChat(item);
        return;
      }
      if (item.serviceType === 47) {
        EventBus.$emit("showRequestChat", item.itemId);
        return;
      }
      if (item.cmsModule === cmsModules.MODULE_EVENT_CALENDAR) {
        this.showItem({
          itemType: 3,
          itemId: item.serviceId,
          cmsModule: item.cmsModule
        });
        return;
      }
      const isSpacesOrder = isSpaceOrderBookingUserData(item);
      if (isSpacesOrder && isSpacesOrder.orderId) {
        this.showSpacesOrderTransaction(item, isSpacesOrder);
        return;
      }
      let url = "/transaction-confirmation?";
      let photograph = item.photographs ? item.photographs[0] : 0;
      if (item.itemType === itemTypes.ITEM_TYPE_BOOKING) {
        url += "bookingId=";
      } else if (item.itemType === itemTypes.ITEM_TYPE_LEAD) {
        url += "requestId=";
        photograph = item.product?.photographs ? item.product.photographs[0] : 0;
      }
      url +=
        item.itemId +
        "&serviceId=" +
        item.serviceId +
        "&imageId=" +
        photograph +
        "&id=" +
        this.$store.state.establishmentHash;
      this.$router.push(url);
    },
    showSpacesOrderTransaction(item, spacesOrder) {
      let bookingItemCurrency = false;
      if (item.currencySymbol) {
        bookingItemCurrency = {
          symbol: item.currencySymbol,
          isoCode: item.isoCode
        };
      }

      const bookingId = spacesOrder.bookingId; //reservation.id.split(":")[0];
      const orderId = spacesOrder.orderId;
      this.$router.push({
        name: "SpacesOrders",
        state: {
          params: cloneDeep({
            bookingId: bookingId,
            orderId: orderId,
            serviceType: getServiceType(item.serviceType),
            serviceId: item.serviceId,
            currencyDataProp: bookingItemCurrency
          })
        }
      });
    },
    showAll(item) {
      const serviceType = getServiceTypeId(serviceTypes[item.cmsModule]);
      const path = getItemUrl(
        item.itemType,
        item.itemId,
        serviceType,
        null,
        item.cmsModule,
        item.serviceId,
        false,
        false,
        false,
        item.calendarType
      );
      const query = {
        id: this.establishmentHash
      };
      if (item.translatableName && translate(item.translatableName).length > 0) {
        query.bubbleName = translate(item.translatableName);
      }
      this.$router.push({
        path: path,
        query: query
      });
    },
    showServiceFunnel(item) {
      this.$router.push({ path: "/booking-form/" + item.itemId });
    },
    ctaClicked(item) {
      switch (item.itemType) {
        case itemTypes.ITEM_TYPE_PRODUCT:
          if (this.moduleIsRequests(item.cmsModule)) {
            this.showRequestsFunnel(item);
          } else {
            this.itemClicked(item);
          }
          break;
        case itemTypes.ITEM_TYPE_SERVICE:
          this.showServiceFunnel(item);
          break;
      }
    },
    moduleIsRequests(cmsModule) {
      const requestsModules = [49, 73, 74]; // ISSUES, HOUSEKEEPING, AMENITIES
      return requestsModules.indexOf(cmsModule) !== -1;
    },
    showRequestsFunnel(item) {
      showRequestFunnel(
        item.serviceId,
        item.itemId,
        undefined,
        item.cmsModule,
        getImageUrl(item.photographs[0], "original"),
        translate(item.translatableName),
        getString(this.strings, serviceTypes[item.cmsModule].toUpperCase() + "_TITLE", this.user),
        this.establishment.id,
        this.user
      );
    }
  }
};

export default Mixin;
