<template>
  <div class="header-widget-1-wrapper" :style="{ 'background-color': backgroundColor }">
    <img
      v-if="backgroundImage"
      class="header-widget-1__image"
      fetchpriority="high"
      :src="getBackgroundImage(backgroundImage, '_ld', '_ld')"
    />
    <div v-if="backgroundVideo">
      <video id="headerBackgroundVideo" muted loop autoplay playsinline class="background-video-frame">
        <source :src="backgroundVideo" type="video/mp4" />
        <source src="/img/icons/apple-touch-icon.png" type='video/webm; codecs="vp8, vorbis"' />
      </video>
    </div>
    <div
      v-if="backgroundImage || backgroundVideo"
      class="background-overlay"
      :style="{ height: resolvedHeight - 1 + 'px' }"
    />

    <div class="header-widget-1-logo-and-buttons" :class="[backgroundImage ? 'with-fade' : '']">
      <div v-if="showExitButton" class="header-widget-1-exit" @click="emitExitEstablishmentEventoToEmbedded">
        <img class="header-widget-1-button-icon__exit" src="../../../assets/log_out_front.png" />
      </div>
      <div v-else-if="showFrontSideMenuButton" class="header-widget-1-exit" @click="showFrontSideMenu">
        <img class="header-widget-1-button-icon" src="../../../assets/dynamic-frontpage/header-icons/White/Menu.png" />
      </div>

      <img v-if="frontpageIcon" class="header-widget-1-logo" :src="getBackgroundImage(frontpageIcon, true)" />

      <div
        v-if="showHeadline"
        class="header-widget-1-headline"
        :style="{ color: headlineColor, 'margin-top': frontpageIcon ? '8px' : '60px' }"
      >
        {{ headlineText }}
      </div>

      <div class="header-widget-1-action-buttons">
        <div v-if="showUserButton" class="user-button">
          <div v-if="!isLogged(user)" class="header-widget-1-button" @click="showUserProfile">
            <img
              class="header-widget-1-button-icon"
              src="../../../assets/dynamic-frontpage/header-icons/White/User.png"
            />
          </div>
          <div v-else class="header-widget-1-button-logged" @click="showUserProfile">
            <img
              class="header-widget-1-button-icon-logged"
              src="../../../assets/dynamic-frontpage/header-icons/White/Room.png"
            />
            <div v-if="userRoom" class="header-widget-1-user-room caption-white medium">{{ userRoom }}</div>
            <!-- <div v-if="userName" class="header-widget-1-user-name caption-white semibold">{{ userName }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <component :is="shapeStyle" v-if="showShapeDecoration" class="header-shape" :fill-color="'#ffffff'" />
  </div>
</template>

<script>
import { getImageUrl, isFullEmbedPWA, isLogged, isMonoHotelApp, translate } from "@/services/utils.js";
import { headerBackgroundModes } from "@/utils/enums/widgetsFrontpage.js";
import HeaderShape1 from "@/components/WidgetsFrontpage/HeaderShapes/HeaderShape1.vue";
import HeaderShape2 from "@/components/WidgetsFrontpage/HeaderShapes/HeaderShape2.vue";

const WIDGET_HEIGHT = 112;
const WIDGET_HEIGHT_WITH_HEADLINE = 180;
const WIDGET_HEIGHT_WITH_VIDEO = 220;
const HEADLINE_MODE_PROPERTY_NAME = 0;
const HEADLINE_MODE_TEXT = 1;
export default {
  components: { HeaderShape1, HeaderShape2 },
  props: {
    widget: {
      type: Object,
      required: true
    },
    frontpageBackgroundColor: {
      type: String,
      required: false
    },
    frontpageTextColor: {
      type: String,
      required: false
    },
    fixedHeaderVisible: {
      type: Boolean,
      required: false
    },
    frontpageData: {
      type: Object || null,
      required: false
    }
  },
  emits: ["height", "showInbox", "showUserProfile", "showFrontSideMenu"],
  computed: {
    frontpageIcon() {
      const url_string = window.location.href;
      const url = new URL(url_string);
      if (url.searchParams.get("icon")) {
        return url.searchParams.get("icon");
      }
      return this.widget.frontpageIcon;
    },
    backgroundColor() {
      if (this.widget.upsell?.showUpsell) {
        return this.widget.upsell?.backgroundColor || this.$store.state.mainColor;
      }
      if (this.widget.backgroundMode === headerBackgroundModes.BACKGROUND_MODE_IMAGE) {
        return "none";
      }
      if (this.widget.backgroundColor) {
        return this.widget.backgroundColor;
      }
      if (this.frontpageBackgroundColor) {
        return this.frontpageBackgroundColor;
      }
      return "#ffffff";
    },
    backgroundImage() {
      if (
        this.widget.backgroundMode === headerBackgroundModes.BACKGROUND_MODE_COLOR ||
        (this.widget.upsell?.showUpsell && (this.widget.upsell?.backgroundColor || this.$store.state.mainColor))
      ) {
        return null;
      }
      return this.widget.backgroundImage;
    },
    backgroundVideo() {
      if (
        this.widget.backgroundMode !== headerBackgroundModes.BACKGROUND_MODE_VIDEO ||
        (this.widget.upsell?.showUpsell && (this.widget.upsell?.backgroundColor || this.$store.state.mainColor))
      ) {
        return null;
      }
      return translate(this.widget.backgroundVideoUrl);
    },
    user() {
      return this.$store.state.user;
    },
    establishment() {
      return this.$store.state.establishment;
    },
    userName() {
      return this.user?.name;
    },
    userRoom() {
      return this.user?.room;
    },
    showUserButton() {
      return this.widget.showUserButton;
    },
    resolvedHeight() {
      let height = WIDGET_HEIGHT;

      if (this.backgroundVideo) {
        height = WIDGET_HEIGHT_WITH_VIDEO;
      }
      if (this.showHeadline) {
        height = WIDGET_HEIGHT_WITH_HEADLINE;
      }
      return height + this.$store.state.embedSafearea;
    },
    showHeadline() {
      return this.widget.showHeadline && translate(this.widget.translatableHeadline).length > 0;
    },
    headlineText() {
      if (this.widget?.headlineMode === HEADLINE_MODE_PROPERTY_NAME) {
        return this.establishment.name;
      }
      return translate(this.widget.translatableHeadline);
    },
    textColor() {
      return this.widget.textColor || "white";
    },
    headlineColor() {
      return this.widget.headlineColor || "white";
    },
    showShapeDecoration() {
      return this.widget.showShapeDecoration && this.widget.shapeStyle > 0 && !this.widget.upsell?.showUpsell;
    },
    shapeStyle() {
      return "HeaderShape" + this.widget.shapeStyle;
    },
    showExitButton() {
      return isFullEmbedPWA() && !this.showFrontSideMenuButton && !isMonoHotelApp();
    },
    showFrontSideMenuButton() {
      return this.frontpageData?.frontSideMenu?.showSideMenu;
    }
  },
  watch: {
    fixedHeaderVisible(val, oldVal) {
      if (this.fixedHeaderVisible) {
        this.pauseVideo();
      } else {
        this.playVideo();
      }
    }
  },
  created() {
    this.$emit("height", this.resolvedHeight);
  },
  methods: {
    getBackgroundImage: getImageUrl,
    isLogged,
    isFullEmbedPWA,
    isMonoHotelApp,
    showInbox() {
      this.$emit("showInbox");
    },
    showUserProfile() {
      this.$emit("showUserProfile");
    },
    playVideo() {
      const vid = document.getElementById("headerBackgroundVideo");
      if (!vid) {
        return;
      }
      vid.play();
    },
    pauseVideo() {
      const vid = document.getElementById("headerBackgroundVideo");
      if (!vid) {
        return;
      }
      vid.pause();
    },
    emitExitEstablishmentEventoToEmbedded() {
      window.location.href = "https://exit-establishment";
    },
    showFrontSideMenu() {
      this.$emit("showFrontSideMenu");
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.header-widget-1-wrapper {
  overflow: hidden;
  height: 100%;
  position: relative;
}
.header-widget-1__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  flex-basis: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}
.background-video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  flex-basis: auto;
  height: auto;
  overflow: hidden;
}
.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  flex-basis: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.header-shape {
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: auto;
  z-index: 12;
}
.header-widget-1-logo-and-buttons {
  padding-top: calc(var(--embed-top-safearea) + (@size-base*8));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  &.with-fade {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.header-widget-1-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  object-fit: cover;
}
.header-widget-1-logo {
  height: 52px;
  width: 140px;
  z-index: 2;
  object-fit: contain;
}
.header-widget-1-headline {
  .body-white;
  .medium;
  margin: 8px 24px 0 24px;
  text-align: center;
  z-index: 2;
}
.header-widget-1-action-buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: calc(var(--embed-top-safearea) + (@size-base*4));
  right: 16px;
  margin-left: auto;
  z-index: 1;
  max-width: 60px;
}
.header-widget-1-exit {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: calc(var(--embed-top-safearea) + (@size-base*4));
  left: 16px;
  z-index: 1;
  background: rgba(19, 19, 25, 0.6);
  border: 1px solid rgba(151, 151, 151, 0.5);
  border-radius: 25px;
  height: 40px;
  width: 40px;
}
.user-button {
  max-width: 60px;
}
.header-widget-1-user-room {
  margin-top: 2px;
}
.header-widget-1-button {
  border-radius: 25px;
  height: 40px;
  width: 40px;
  background: rgba(19, 19, 25, 0.6);
  border: 1px solid rgba(151, 151, 151, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.header-widget-1-button-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
  &__exit {
    height: 30px;
    width: 30px;
    margin-right: 4px;
  }
}
.header-widget-1-button-icon-logged {
  height: 16px;
  width: 16px;
  object-fit: contain;
}
.header-widget-1-button-logged {
  border-radius: 4px;
  height: 48px;
  width: 60px;
  flex-basis: 60px;
  background: rgba(19, 19, 25, 0.6);
  border: 1px solid rgba(151, 151, 151, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
